import "es6-promise/dist/es6-promise.auto.min";
import "whatwg-fetch";
import ProductShell from "@/components/ProductShell.vue";
import RecaptchaClient from "@/chipply/verify/RecaptchaClient";
import vuetify from "@/plugins/vuetify";

(window as any).ProductShell = ProductShell;
(window as any).RecaptchaClient = RecaptchaClient;
(window as any).vuetify = vuetify;

export default ProductShell;
